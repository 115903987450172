<script lang="ts" setup>
import { useCookieConsentStore } from '~/stores/useCookieConsentStore'
import { ref, watch } from 'vue'

const ccStore = useCookieConsentStore()
const countryCode = ref<string | null>(null)

// Compute fetch parameters based on country code availability
const params = computed(() => ({
  limit: 6,
  ...(countryCode.value && { countryCode: countryCode.value }),
}))

// Fetch station data with cache control
const nuxtApp = useNuxtApp()
const {
  data: stations,
  execute,
  pending,
} = useFetch('/api/stations/', {
  key: 'listen-now',
  params,
  getCachedData: (key) => nuxtApp.payload.data[key] || nuxtApp.static.data[key],
  transform: (response) => response.data,
  server: false,
  immediate: false,
})

// Watch for consent changes and execute fetch if consented
watch(
  () => ccStore.consented,
  () => {
    if (ccStore.consented && !stations.value) {
      countryCode.value = ccStore.gdpr?.country || null
      execute()
    }
  },
  { immediate: true }
)
</script>

<template>
  <div v-if="pending" class="grid sm:grid-cols-3 grid-cols-2 gap-1 animate-pulse">
    <div
      v-for="(_, index) in 6"
      :key="index"
      class="flex-1 min-w-32 bg-zeno-gray-light rounded-xl py-6 space-y-6 lg:px-6 px-4 animate-pulse"
    >
      <div
        class="aspect-square min-h-24 w-full drop-shadow-[0_6px_10px_rgba(0,0,0,0.04)] bg-white border border-zeno-gray-border-light rounded-full inline-block"
      />
      <div class="h-4 bg-white rounded" />
    </div>
  </div>

  <div v-else-if="stations" class="grid sm:grid-cols-3 grid-cols-2 gap-1">
    <StationListGridItem v-for="(item, index) in stations" :key="index" :item="item" />
  </div>
</template>

<style scoped></style>
